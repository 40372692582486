.page-account {
  padding: 10px 20px;
  .account-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 4px;
    &:hover {
      box-shadow: 0px 1px 20px 1px rgb(0 0 0 / 20%);
    }
    .icon .iconfont {
      font-size: 28px;
      color: #000;
      margin-right: 20px;
    }
  }
}
@media (max-width: 500px) {
  .page-account {
    padding: 10px 0;
  }
}
