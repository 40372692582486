.page-book {
  margin: 0 10px;
  padding: 20px;
  .book-header {
    position: relative;
    .back {
      position: absolute;
      top: 5px;
      left: 0;
    }
    .step {
      display: flex;
      width: 80%;
      margin: 0 auto;
      overflow: hidden;
      .iconfont {
        font-size: 30px;
        color: #000;
      }
    }
    .ant-steps {
      flex-wrap: nowrap;
      .ant-steps-item {
        flex: none;
        width: 150px;
      }
      .ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55px;
        height: 55px;
        border-radius: 50px;
        background: #ccc;
      }
      .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background: #009bff;
      }
      .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
        background: #52c41a;
      }
      .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background: #009bff;
      }
      .ant-steps-item-title::after {
        top: 25px;
        background: #009bff;
      }
    }
  }
  .book-title {
    margin: 20px 10px;
    text-align: center;
    color: #000;
    font-size: 20px;
    font-weight: bold;
  }
  .book-list {
    margin: 0 auto;
    .book-item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      border-radius: 8px;
      padding: 10px;
      cursor: pointer;
      border: 1px solid transparent;
      box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 20%);
      &:hover {
        border-color: #4096ff;
      }
    }
    .item-three {
      justify-content: space-between;
      color: rgba(0, 0, 0, 0.66);
    }
  }

  .book-payment-edit {
    width: 80%;
    margin: auto;
    .card {
      position: relative;
      background: #dd4435;
      width: 100%;
      height: 180px;
      border-radius: 8px;
      font-size: 18px;
      .card-name {
        position: absolute;
        top: 50px;
        left: 20px;
      }
      .card-date {
        position: absolute;
        top: 90px;
        left: 20px;
      }

      .card-holder {
        position: absolute;
        top: 130px;
        left: 20px;
      }
    }
    .form {
      margin-top: 30px;
      .item {
        display: flex;
        justify-content: space-between;
      }
      .ant-input {
        background: transparent;
      }
      .ant-picker {
        background: transparent;

        &:not(.ant-picker-disabled).ant-picker-status-error:not([disabled]):hover {
          background: transparent;
        }
      }
    }

    .save {
      text-align: center;
      margin: 40px auto;
    }
  }

  .doc-select-type {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;

    .book-sub-title {
      margin-bottom: 20px;
    }

    .doc-type-title {
      text-align: center;
    }
    .doc-type-con {
      display: flex;
      justify-content: space-evenly;
    }
    .doc-type-item {
      min-width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .doc-item-tetx {
      padding: 20px 5px;
    }
  }

  .book-three {
    width: 75%;
    margin: 0 auto;
  }

  .book-four {
    .four-date {
      display: flex;
      align-items: center;
      box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 20%);
      padding: 10px;
      margin: 20px 0;
      .date-picker {
        flex: 1;
        text-align: center;
      }
    }
    .four-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      border-radius: 8px;
      padding: 20px;
      box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 20%);
      .item-hender {
        display: flex;
        padding: 0 30px;
        margin-bottom: 20px;
        .ant-avatar {
          margin-right: 20px;
        }
        .header-con {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .con-name {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 5px;
        }
      }
      .item-footer {
        overflow-x: auto;
        overflow: -moz-scrollbars-none;
        &::-webkit-scrollbar {
          width: 6 !important;
        }
      }
    }
  }
  .book-five {
    .icon {
      padding: 5px;
      margin-right: 30px;
      .iconfont {
        font-size: 30px;
        color: #000;
      }
    }

    .five-item {
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
  }
  .book-six {
    .ant-result-icon > .anticon {
      font-size: 110px;
    }
  }

  .four {
    .list-table {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      .table-item {
        display: flex;
        .ant-table-wrapper {
          flex: 1;
        }
      }
      .btn-title {
        flex: none;
        width: 20px;
        height: 45px;
      }
      .btn-bottom {
        width: 150px;
        height: 26px;
        padding: 0 15px;
        margin: auto;
        margin-top: 10px;
      }
    }
    .ant-list .ant-list-item {
      flex-direction: column;
      align-items: unset;
    }
    .table-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
    }
    .ant-table-wrapper .ant-table-thead > tr > th {
      padding: 4px;
    }
    .ant-table-wrapper .ant-table-tbody > tr > td {
      padding: 4px;
      font-size: 12px;
      border-bottom: none;
    }
    .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td {
      background: transparent;
    }
    .ant-tag {
      margin: 0;
      border: none;
      font-size: 12px;
      width: 100%;
      color: #333;
      text-align: center;
      &:hover {
        color: #fff;
        background-color: #4096ff !important;
      }
    }
    .noData {
      height: 100px;
      color: #000;
      .nodata-con {
        height: 80%;
        width: 80%;
        max-width: 300px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #ffeb3b;
      }
      .isMobile {
        padding: 5px 20px;
      }
    }
    .lock-time {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .about {
    color: rgba(0, 0, 0, 0.85);
    margin-top: 10px;
    p {
      margin-bottom: 0;
    }
  }
  .about-main {
    position: relative;
    &.guanbi {
      max-height: 40px;
      overflow: hidden;
    }
    &.open {
      height: auto;
    }
    .mack {
      position: absolute;
      width: 100%;
      height: 40px;
      bottom: 0;
      left: 0;
      background: linear-gradient(rgba(#fff, 0), #fff);
    }
  }
}
@media (max-width: 500px) {
  .page-book {
    padding: 20px 0;
    .book-header {
      .step {
        .iconfont {
          font-size: 20px;
        }
      }
      .ant-steps {
        .ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
          width: 40px;
          height: 40px;
        }
      }
    }
    .book-three {
      width: 100%;
    }
    .book-list {
      .book-item {
        font-size: 14px;
      }
    }
    .book-payment-edit {
      width: 100%;
    }
  }
}
@media (width > 510px) {
  .page-book {
    .four {
      .ant-tag {
        line-height: 35px;
        font-size: 14px;
      }
    }
  }
}
