.page-payment {
  padding: 10px 20px 0;
  .payment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .payment-content {
    width: 100%;
    position: absolute;
    top: 124px;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    padding: 20px 30px;
  }
  .payment-item {
    display: flex;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 1px 10px 1px rgb(0 0 0 / 20%);
    padding: 20px;
    margin: 10px 20px;
    .icon {
      flex: none;
      margin-right: 20px;
      .iconfont {
        font-size: 30px;
      }
    }
    .action {
      flex: none;
      .iconfont {
        font-size: 20px;
        cursor: pointer;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      flex: 1;
      div {
        line-height: 22px;
      }
    }
  }
}

.page-payment-edit {
  height: calc(100vh - 80px);
  padding: 20px;
  .card {
    position: relative;
    background: #dd4435;
    width: 100%;
    height: 180px;
    border-radius: 8px;
    font-size: 18px;
    .card-name {
      position: absolute;
      top: 50px;
      left: 20px;
    }
    .card-date {
      position: absolute;
      top: 90px;
      left: 20px;
    }

    .card-holder {
      position: absolute;
      top: 130px;
      left: 20px;
    }
  }
  .form {
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
    .item {
      display: flex;
      justify-content: space-between;
    }
    .ant-input {
      background: transparent;
    }
    .ant-picker {
      background: transparent;

      &:not(.ant-picker-disabled).ant-picker-status-error:not([disabled]):hover {
        background: transparent;
      }
    }
  }

  .save {
    text-align: center;
    margin: 40px auto;
  }
  .back {
    text-align: center;
    .ant-btn {
      background: rgba(0, 0, 0, 0.06);
      font-size: 16px;
    }
  }
}
@media (max-width: 500px){
  .page-payment-edit{
    .form{
    width: 100%;
    }
  }
}