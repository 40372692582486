.page-historical {
  padding: 10px 20px 0;
  .historical-content {
    width: 100%;
    position: absolute;
    top: 114px;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    padding: 20px 30px;
  }
  .item {
    display: flex;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0px 1px 10px 1px rgb(0 0 0 / 20%);
    padding: 20px 0;
    .avatar {
      flex: none;
      margin: 10px 30px;
    }
    .con-list {
      flex: 1;
    }
    .con-title {
      font-size: 16px;
      font-weight: bold;
    }
    .con-item {
      display: flex;
      padding: 10px 0;
      .iconfont {
        color: rgb(0, 166, 255);
      }
      .icon {
        flex: none;
        margin-right: 10px;
      }
      .value {
        flex: 1;
      }
    }
  }
}
