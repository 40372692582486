@font-face {
  font-family: "iconfont"; /* Project id 3759341 */
  src: url('iconfont.woff2?t=1683647015686') format('woff2'),
       url('iconfont.woff?t=1683647015686') format('woff'),
       url('iconfont.ttf?t=1683647015686') format('truetype'),
       url('iconfont.svg?t=1683647015686#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.doc-bianji:before {
  content: "\e601";
}

.doc-shanchu:before {
  content: "\e8c1";
}

.doc-exit:before {
  content: "\e62e";
}

.doc-lishijilu:before {
  content: "\e607";
}

.doc-gerenshezhi:before {
  content: "\e633";
}

.doc-ccmastercard:before {
  content: "\ea31";
}

.doc-xinyongqia:before {
  content: "\e6a7";
}

.doc-cc-visa:before {
  content: "\e653";
}

.doc-yonghu:before {
  content: "\e652";
}

.doc-clock:before {
  content: "\e627";
}

.doc-dianhua:before {
  content: "\e613";
}

.doc-dizhi:before {
  content: "\e609";
}

.doc-duigou-cu:before {
  content: "\e621";
}

.doc-riqi:before {
  content: "\e65a";
}

.doc-kongxinwenhao:before {
  content: "\ed19";
}

.doc-jurassic_users:before {
  content: "\e695";
}

.doc-tianjiayonghu:before {
  content: "\e600";
}

.doc-yiyuan:before {
  content: "\e6e1";
}

.doc-jiaofuriqi:before {
  content: "\e667";
}

.doc-mianxingyishengtubiao3:before {
  content: "\e6a3";
}

.doc-liebiao:before {
  content: "\e60f";
}

.doc-rili:before {
  content: "\e620";
}

.doc-buzou:before {
  content: "\e6af";
}

.doc-user:before {
  content: "\e60e";
}

.doc-user1:before {
  content: "\e957";
}

.doc-pc:before {
  content: "\e603";
}

.doc-ipad:before {
  content: "\e62d";
}

.doc-iphone:before {
  content: "\e824";
}

