.page-family {
  padding: 10px 20px 0;
  .family-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .family-content {
    width: 100%;
    position: absolute;
    top: 134px;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    padding: 20px 30px;
  }
  .family-item {
    display: flex;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 1px 10px 1px rgb(0 0 0 / 20%);
     padding: 20px;
    margin: 10px 20px;
    .icon {
      flex: none;
      margin-right: 20px;
      .ant-avatar {
      background: rgb(12, 90, 248);}
    }
    .action {
      flex: none;
      .iconfont {
        font-size: 20px;
        cursor: pointer;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      flex: 1;
      div {
        line-height: 22px;
      }
    }
  }
}

.page-family-edit {
  padding: 10px 20px;
}
