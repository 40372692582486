.page-layouts {
  .page-header {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px 0 20px;
    height: 80px;
    z-index: 9999;
    .header-left {
      display: flex;
      flex-direction: column;
      font-size: 18px;
    }
    .header-left-subtitle {
      font-size: 15px;
      margin-top: 2px;
      transform: scale(0.9) translateX(-8px);
    }
    .header-right {
      display: flex;
      .header-right-item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #000;
        .iconfont {
          font-size: 20px;
          margin-bottom: 6px;
        }
        &.active {
          color: #1677ff;
        }
      }
    }
    .clear {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .page-content {
    max-width: 1120px;
    margin: 0 auto;
    padding-top: 80px;
  }
  .ant-breadcrumb a {
    color: #1677ff;
  }
}
@media (max-width: 500px) {
  .page-layouts {
    .page-header {
      padding: 10px;
      height: 60px;
      .header-left {
        font-size: 14px;
      }
      .header-left-subtitle {
        font-size: 10px;
        margin-top: 2px;
        transform: scale(0.9) translateX(-8px);
      }
      .header-right {
        .header-right-item {
          font-size: 12px;
          .iconfont {
            font-size: 18px;
          }
        }
      }
    }
    .page-content {
      padding-top: 60px;
    }
  }
}
