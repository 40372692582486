.login-form {
  max-width: 400px;
  margin: 50px auto;
  .login-form-forgot {
    float: right;
  }
  .ant-col-rtl .login-form-forgot {
    float: left;
  }
  .login-form-button {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .login-form {
    margin: 50px 20px;
  }
}